"use client";

import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const posthog = usePostHog();

  useEffect(() => {
    posthog.captureException(error);
  }, [error, posthog]);

  return (
    // global-error must include html and body tags
    <html>
      <body>
        <div className="relative min-h-screen bg-black text-white">
          <div className="absolute left-6 top-6">
            <svg
              className="h-12 w-12"
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="128.000000pt"
              height="128.000000pt"
              viewBox="0 0 128.000000 128.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
              >
                <path d="M347 1063 c-4 -3 -7 -15 -7 -25 0 -14 -8 -18 -35 -18 -19 0 -35 -4 -35 -10 0 -5 45 -11 103 -12 83 -2 102 -6 102 -18 0 -11 -15 -16 -63 -18 -44 -2 -65 -7 -69 -17 -7 -20 -39 -19 -47 0 -7 20 -62 20 -70 0 -3 -10 -16 -14 -38 -13 -24 2 -33 -1 -33 -12 0 -11 9 -14 31 -13 19 2 37 -4 44 -12 16 -19 44 -19 60 0 8 9 30 15 56 15 42 0 44 -1 44 -30 l0 -30 -115 0 c-70 0 -123 5 -134 12 -24 15 -53 3 -49 -19 3 -16 17 -18 151 -14 133 3 147 1 147 -14 0 -14 -9 -16 -52 -13 -42 2 -53 0 -53 -12 0 -12 11 -14 53 -12 43 3 52 1 52 -13 0 -15 -13 -16 -117 -14 -96 2 -119 0 -121 -12 -2 -12 18 -13 118 -10 l120 3 0 -41 0 -41 -55 0 c-30 0 -55 5 -55 10 0 16 -46 11 -61 -6 -11 -13 -25 -15 -72 -10 -44 4 -58 3 -54 -7 3 -8 22 -11 60 -9 42 3 61 0 72 -12 19 -18 41 -21 50 -6 3 6 31 10 61 10 40 0 54 -4 54 -14 0 -11 15 -16 52 -18 71 -4 76 -26 6 -30 -50 -3 -53 -5 -56 -30 l-3 -28 79 0 c83 0 99 -5 81 -27 -15 -17 -181 -18 -208 -1 -26 16 -53 3 -49 -24 3 -24 6 -24 78 -13 33 6 37 4 64 -40 64 -103 165 -150 322 -150 96 0 105 2 170 35 48 25 81 51 111 87 67 83 73 118 73 408 0 300 4 290 -110 290 -112 0 -108 9 -112 -284 -3 -218 -5 -250 -21 -274 -63 -96 -219 -88 -269 13 -16 30 -18 66 -18 270 0 129 -4 240 -8 247 -4 6 -20 17 -35 22 -31 12 -180 15 -190 4z m663 -317 c0 -133 -4 -236 -11 -253 -18 -48 -59 -100 -101 -128 -57 -38 -70 -17 -14 22 24 17 56 52 71 77 27 47 27 47 30 274 1 125 3 228 4 230 0 1 6 2 11 2 6 0 10 -81 10 -224z" />
                <path d="M300 575 c-25 -30 15 -70 45 -45 19 16 19 25 -1 44 -19 20 -28 20 -44 1z" />
                <path d="M195 560 c-8 -14 3 -30 21 -30 8 0 14 9 14 20 0 21 -24 28 -35 10z" />
              </g>
            </svg>
          </div>

          <div className="flex min-h-screen flex-col items-center justify-center px-4">
            <h1 className="mb-4 text-6xl font-bold">500</h1>
            <h2 className="mb-6 text-2xl font-semibold">
              ¡Ups! Error del servidor
            </h2>
            <p className="mb-4 max-w-md text-center text-lg">
              Ha ocurrido un error en nuestros servidores.
            </p>

            <button
              onClick={() => reset()}
              className="rounded border border-white px-6 py-2 text-sm font-medium text-white transition-colors hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black"
            >
              Intentar de nuevo
            </button>
          </div>
        </div>
      </body>
    </html>
  );
}
